import Request from '../utils/request'
// api 地址

export const headersLanguageMap = {
  'vn': 'vi_VN',
  'vi': 'vi_VN',
  'en': 'en_US',
  'th': 'th_TH'
}

export const handleHeaders = (header = {}) => {
  // console.log(', language: headersLanguageMap[header.language] || header.language', headersLanguageMap[header.language] || header.language)
  return { ...header }
}

const domain = ''

// api服务地址
const apiMall = domain + ''

// 发送验证码
const sendVerifiCodeThai = (params, header = {}) => Request(params, apiMall + '/pro/prm/verificationCode/register/send', 'post', handleHeaders(header))
const sendVerificationCode = (params, header = {}) => Request(params, apiMall + '/pro/prm/verificationCode/send', 'post', handleHeaders(header))
const checkFuseID = (params, header = {}) => Request(params, apiMall + '/pro/partner/invited/person/check', 'post', handleHeaders(header))
const register = (params, header = {}) => Request(params, apiMall + '/pro/prm/partner/register', 'post', handleHeaders(header))

const sendVerifiCodeThaiTPB = (params, header = {}) => Request(params, apiMall + '/pro/verificationCode/create/partner/send', 'post', handleHeaders(header))
const registerTPB = (params, header = {}) => Request(params, apiMall + '/local/partner/batch/completionDataCreatePartner', 'post', handleHeaders(header))
const preflight = (params, header = {}) => Request(params, apiMall + '/local/partner/batch/check/completionDataCreatePartner', 'post', handleHeaders(header))
const queryRegionList = (params, header = {}) => Request(params, apiMall + '/pro/sys/list/region/parent', 'post', handleHeaders(header))

// installment Consent
const banchaConsentAllow = (params, header = { language: 'th_TH' }) => Request(params, apiMall + '/local/authorization/customer/personal/information/allow', 'post', handleHeaders(header))

// Aeci X Tab
const sendVerifiCodeAeciTab = (params, header = {}) => Request(params, apiMall + '/pro/verificationCode/bind/register/send', 'post', handleHeaders(header))
const registerAeciTab = (params, header = {}) => Request(params, apiMall + '/pro/user/account/binding', 'post', handleHeaders(header))

// rm 邀请链接检查
const rmLinkCheck = (params, header = {}) => Request(params, apiMall + '/pro/partner/invited/rmLinks/check', 'post', handleHeaders(header))

export default {
  sendVerifiCodeThai,
  sendVerifiCodeAeciTab,
  sendVerificationCode,
  checkFuseID,
  register,
  registerAeciTab,
  sendVerifiCodeThaiTPB,
  registerTPB,
  preflight,
  queryRegionList,
  banchaConsentAllow,
  rmLinkCheck
}
