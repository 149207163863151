/**
 * @Author:       huangjiaquan
 * @Date:         2023-07-05 10:42
 * @LastEditTime: 2023-07-05 10:42
 * @LastEditors:  huangjiaquan
 * @FilePath:     fusePro-web/src/directive
 */

import * as directives from './instruction'

// 批量注册指令
export default {
  install(Vue) {
    Object.keys(directives).forEach((key) => {
      Vue.directive(key, directives[key])
    })
  }
}
