// import parseTime, formatTime and set to filter
// export { parseTime, formatTime }
//   from '@/utils'
import dayjs from 'dayjs'
import store from '@/store'
import { codeMap } from '@/utils/codeMap'
import { Session } from '@/utils/storage'

const timeZone = store.state.base.timeZone || 7
const dateFormat = 'dd/MM/yyyy'
const defaultChronology = 'ADCalendar' // 'ADCalendar'公历  'BuddhistCalendarI' 泰国佛历

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/**
 * @param {number} time
 */
export function timePeriod(time) {
  if (time < 3600) {
    return pluralize(~~(time / 60), ' minute')
  } else if (time < 86400) {
    return pluralize(~~(time / 3600), ' hour')
  } else {
    return pluralize(~~(time / 86400), ' day')
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/**
 * Upper case first char
 * @param {String} string
 */
export function numPecent(value) {
  value = Number(value)
  return CHECKIFVALUEISNAN(value) ? '-' : value.toFixed(2)
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Upper case first char
 * @param {String} string
 */
export function makeDataCorrect(value) {
  return CHECKIFVALUEISUNDEFINED(value)
}

/**
 * 默认 => "dd/mm/yyyy"
 * @param {time} 接受时间戳, 约定接口都是毫秒数
 */

export function formatDateId(time, cFormat = dateFormat, chronology = defaultChronology) {
  if (!time) return '-'
  let date
  if (typeof time === 'object') {
    date = time
  }
  if (typeof time === 'string') {
    if (/^-?[0-9]+$/.test(time)) { // '1654864338596' 时间戳字符串
      time = parseInt(time)
    } else { // '2022-06-10'
      date = new Date(time)
    }
  }
  let offsetZo
  if (typeof time === 'number') {
    const offset_GMT = new Date(time).getTimezoneOffset() / 60
    offsetZo = (parseInt(timeZone) + offset_GMT) * 60 * 60 * 1000 // 时区毫秒
    date = new Date(time + offsetZo)
  }

  if (cFormat.indexOf('{') >= 0) {
    return oldDateFormat(date, cFormat, chronology)
  }
  return handleDateFormat(date, cFormat, chronology)
}

export function handleDateFormat(date, cFormat, chronology) {
  let format = cFormat || dateFormat
  format = format.replace('dd/MM/yyyy', 'DD/MM/YYYY')
  let result = dayjs(date).format(format)
  if (chronology === 'BuddhistCalendarI') {
    result = result.replace(/2[0-9]{3}/, $0 => $0 - 0 + 543)
  }
  return result
}
// eslint-disable-next-line no-unused-vars
function oldDateFormat(date, cFormat, chronology) {
  const format = cFormat || '{d}/{m}/{y}'
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
    b: date.getMonth()
  }

  let time_str = format.replace(/{([ymdhisab])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][value] }
    if (key === 'b') { return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Spt', 'Oct', 'Nov', 'Dec'][value] }
    return value.toString().padStart(2, '0')
  })
  if (chronology === 'BuddhistCalendarI') {
    time_str = time_str.replace(/2[0-9]{3}/, $0 => $0 - 0 + 543)
  }
  return CHECKIFVALUEISUNDEFINED(time_str) || ''
}

/**
 * 默认 => "dd/mm/yyyy"
 * @param {num} 格式化币种
 */
/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num, local) {
  if (num === '' || num === null || num === undefined) { return '-' }
  if (isNaN(num * 1)) { return num }
  return FunCurrency(num, local)
}

export function formatCurrencyNum(num, local) {
  // return FunCurrency(num, local)
  return FunCurrency(num, local)
}

export function formatCurrency(num, minimumFractionDigits = 0) {
  // return FunCurrency(num, local)
  return handleCurrencyFormatting(num, minimumFractionDigits)
}

// 需要切换分隔符的国家, toLocaleString 默认返回的分隔符与商务需求不符, 需要切换
const needToggleCountries = {
  vi: true
}
export function handleCurrencyFormatting(num, minimumFractionDigits = 0) {
  if (!num && num !== 0) return '-'
  if (num == '***') return num
  if (typeof num !== 'number') { num = Number(num) }
  const { currency = 'VND', region = 'TH' } = Session.get('appInfo') || {}
  const country = region

  const result = CHECKIFVALUEISNAN(num) ? '-' : num.toLocaleString(country, {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits
  })
  return needToggleCountries[country] ? toggleSeparator(result) : result
}
// 切换千分位分隔符
export function toggleSeparator(income) {
  return income.replace(/[\.\,]/g, (val) => val === '.' ? ',' : val === ',' ? '.' : val)
}

/**
 * 判断是否是数字
 * 如果是脱敏数据直接返回，如***
 * @param {number} num
 */
export function isNum(num) {
  if (num == '***') {
    return num
  }
  if (typeof num !== 'number') {
    num = Number(num)
  }
  num = num.toFixed(2)
  return CHECKIFVALUEISNAN(num) ? '-' : num
}

/**
 * 根据字典决定状态对应显示的字段内容
 * @param {type,key, option, defaultData} 状态类弄，状态值, 自定义数据字典, 默认值（比如:policyType,101, {0: 'No', 1: 'Yes'}, 1）
 */

export function dictionaryMatch(key, type, option, defaultData) {
  if (key == '***') {
    return key
  }
  if (!key && defaultData) {
    return defaultData
  }
  if (option) {
    return option[key] ? CHECKIFVALUEISUNDEFINED(option[key]) : '-'
  }
  const dictionaries = codeMap()
  return dictionaries[type] && dictionaries[type][key] ? CHECKIFVALUEISUNDEFINED(dictionaries[type][key]) : '-'
}

export function funGetTime(date, type) {
  return date ? new Date(date).getTime() : ''
}

// 统一货币格式化
function FunCurrency(num, local) {
  // const currency = Session.get('lang') || 'vi'
  const currency = 'en'
  if ((!num || num == null) && num !== 0) {
    return '-'
  }
  if (num == '***') {
    return num
  }
  if (typeof num !== 'number') {
    num = Number(num)
  }
  const site = {
    'vi': 'vi-VN',
    'id': 'id-ID',
    'en': 'en-US',
    'zh': 'zh-HK'
  }
  const name = site[currency] || 'vi-VN'
  return CHECKIFVALUEISNAN(num) ? '-' : num.toLocaleString(name)
}

// 判断键值是否不为NaN
function CHECKIFVALUEISNAN(value) {
  return isNaN(value) || value === undefined || value === null
}

// 判断键值是否不为undefined
function CHECKIFVALUEISUNDEFINED(value) {
  return value === undefined || value === null ? '-' : value
}
