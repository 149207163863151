import { Local } from '@/utils/storage'

const state = {
  mpCode: '',
  categoryUid: '',
  displayCategoryId: '',
  mpInfo: {},
  quotationForm: {},
  quotationFormSchema: {},
  priceForm: {},
  productDetails: {},
  coverageSteps: [],
  packageList: [],
  selPlan: {},
  compareObj: {},
  benfitInfo: {},
  prePayInfo: {},
  needQueryListData: 1,
  needReorderData: 0
}

const mutations = {
  CHANGE_MPCODE: (state, value) => {
    state.mpCode = value
    Local.set('mpCode', value)
  },
  CHANGE_MPINFO: (state, value) => {
    state.mpInfo = value
    Local.set('mpInfo', value)
  },
  SET_QUOTATION_FORM: (state, value) => {
    state.quotationForm = value
  },
  SET_QUOTATION_FORM_SCHEMA: (state, value) => {
    state.quotationFormSchema = value
  },
  SET_PRICE_FORM: (state, value) => {
    state.priceForm = value
  },
  CHANGE_CATEGORY_UID: (state, value) => {
    state.categoryUid = value
  },
  CHANGE_DISPLAY_CATEGORY_ID: (state, value) => {
    state.displayCategoryId = value
  },
  SET_PRODUCT_DETAILS: (state, value) => {
    state.productDetails = value
  },
  SET_COVERAGESTEPS: (state, value) => {
    state.coverageSteps = value
  },
  SET_PACKAGELIST: (state, value) => {
    state.packageList = value
  },
  SET_SELPLAN: (state, value) => {
    state.selPlan = value
  },
  SET_COMPAREOBJ: (state, value) => {
    state.compareObj = value
  },
  SET_BENFITINFO: (state, value) => {
    state.benfitInfo = value
  },
  SET_PREPAYINFO: (state, value) => {
    state.prePayInfo = value
  },
  SET_needQueryListData: (state, value) => {
    state.needQueryListData = value
  },
  SET_needReorderData: (state, value) => {
    state.needReorderData = value
  }
}

const actions = {
  changeMpCode({ commit }, data) {
    commit('CHANGE_MPCODE', data)
  },
  changeMpInfo({ commit }, data) {
    commit('CHANGE_MPINFO', data)
  },
  setQuotationForm({ commit }, data) {
    commit('SET_QUOTATION_FORM', data)
  },
  setQuotationFormSchema({ commit }, data) {
    commit('SET_QUOTATION_FORM_SCHEMA', data)
  },
  setPriceForm({ commit }, data) {
    commit('SET_PRICE_FORM', data)
  },
  changeCategoryUid({ commit }, data) {
    commit('CHANGE_CATEGORY_UID', data)
  },
  changeDisplayCategoryId({ commit }, data) {
    commit('CHANGE_DISPLAY_CATEGORY_ID', data)
  },
  setProductDetails({ commit }, data) {
    commit('SET_PRODUCT_DETAILS', data)
  },
  setCoverageSteps({ commit }, data) {
    commit('SET_COVERAGESTEPS', data)
  },
  setPackageList({ commit }, data) {
    commit('SET_PACKAGELIST', data)
  },
  setSelPlan({ commit }, data) {
    commit('SET_SELPLAN', data)
  },
  setCompareObj({ commit }, data) {
    commit('SET_COMPAREOBJ', data)
  },
  setBenfitInfo({ commit }, data) {
    commit('SET_BENFITINFO', data)
  },
  setPrePayInfo({ commit }, data) {
    commit('SET_PREPAYINFO', data)
  },
  setNeedQueryListData({ commit }, data) {
    commit('SET_needQueryListData', data)
  },
  setNeedReorderData({ commit }, data) {
    commit('SET_needReorderData', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
