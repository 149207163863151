<!--
 * @Author       : Simba
 * @Date         : 2022-02-07 19:01:46
 * @LastEditors  : Simba
 * @LastEditTime : 2022-02-23 17:03:22
 * @Description  : message
 * @FilePath     : /vn-register/src/App.vue
-->
<template>
  <div
    id="app"
    class="app-container"
    :class="{'fu-hidden-fixed': isFixedBottomHidden, 'for-tpb': isTPB}"
  >
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
import FixedMixins from './mixins/fixed'
import { Session } from '@/utils/storage'
import { dispatchAction } from '@/utils/dispatch'

export default {
  name: 'App',
  mixins: [FixedMixins],
  data() {
    return {
      appInfo: Session.get('appInfo') || {},
      maxWidth: 1000
    }
  },
  computed: {
    agentUid() {
      return this.$store.state.base.agentUid
    },
    isTHEnv() {
      const { region = '' } = this.$route.query
      const rc = region || this.$store.state.base.region || this.appInfo.region
      return rc.toLowerCase() == 'th'
    },
    isTPB() {
      const { isTPB = '' } = this.$route.query
      return (isTPB == 1) || (this.appInfo.isTPB == 1)
    }
  },
  watch: {
    $route: {
      handler(val) {
        // 测试方便临时
        if (val.query.t || val.query.token) {
          Session.set('token', val.query.t || val.query.token)
        }

        const appInfo = Session.get('appInfo') || {}
        const agentUid = this.$store.state.base.agentUid || appInfo.agentUid || ''
        if (agentUid) {
          document.title = this.$route?.meta?.title ? this.$t(`meta.${this.$route.meta.title}`) : ''
        } else {
          document.title = ''
        }
        if (this.$route.query.isTPB == 1) {
          appInfo.isTPB = 1
          this.appInfo = appInfo
          Session.set('appInfo', appInfo)
        }
        this.initDesktopConfig()
      },
      deep: true
    },

    agentUid(val) {
      if (!val) {
        document.title = ' '
      }
    }
  },
  async created() {
    if (!this.agentUid) {
      document.title = ' '
    }
    if (window.getBaseConfig) {
      window.getBaseConfig = (res = {}) => {
        this.$store.dispatch('base/changeLang', res.lan || 'en')
        this.$i18n.locale = res.lan || 'en'
      }
    }
  },
  mounted() {
    if (window.history && window.history.pushState) {
      // history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.backApp, false)
    }

    this.initIptScrollIntoView()

    // this.initDesktopConfig()
  },
  destroyed() {
    window.removeEventListener('popstate', this.backApp, false)
  },
  methods: {
    initDesktopConfig() {
      this.setHtmlFontSize()
      if (this.isTPB) {
        document.body.classList.add('body-is-TPB')
      } else {
        document.body.classList.remove('body-is-TPB')
      }
    },
    setHtmlFontSize() {
      const docEle = document.documentElement
      const isBigScreen = docEle.clientWidth >= (this.maxWidth - 300)
      if (isBigScreen) {
        docEle.style.fontSize = `${(this.maxWidth - 300) / 10 - 25}px`
        // 👇️ 向body标签添加多个类
        document.body.classList.add('body-isBigScreen')
      } else {
        document.body.classList.remove('body-isBigScreen')
      }
      const desktopConfig = { maxWidth: this.maxWidth + 'px', isBigScreen }
      this.$store.dispatch('base/setDesktopConfig', desktopConfig)
    },
    backApp() {
      const route = this.$route
      if (route.name == 'Product Details' || route.name == 'new Product Details') {
        dispatchAction({
          method: 'jumpToProductListPage',
          params: {
            categoryUid: this.$store.state.policy.categoryUid || ''
          }
        })
      } else if (route.name == 'Quotation Form') {
        window.location.href = 'appBack://page'
      }
    },

    // 监听方法 让输入框在获取焦点的时候出现在可视区域的中间
    initIptScrollIntoView() {
      window.addEventListener('resize', () => {
        this.setHtmlFontSize()
        if (document.activeElement.tagName == 'INPUT' || document.activeElement.tagName == 'TEXTAREA') {
          // 这里放计时器是因为有些机型的键盘弹出比较慢
          window.setTimeout(() => {
            document.activeElement.scrollIntoViewIfNeeded()
          }, 300)
        }
      })
    }
  }
}
</script>
<style lang="scss">
// 自定义进度条颜色
#nprogress .bar {
  background: #F811B2 !important; //自定义颜色
}

.fu-hidden-fixed {
  .fu-fixed {
    display: none !important;
  }
}
.app-container {
  &.for-tpb {
    .progress-bar {
      .step {
        background: $tpbThemeColor;
        &.not-arrived{
          background: #EDEDED;
        }
      }
    }
    .plan-header {
      background-color: $tpbThemeColor;
      &.clear {
        background-color: white;
      }
    }
    .details-con {
      .de-tit {
        color: $tpbThemeColor;
      }
      .c_name {
        color: $tpbThemeColor;
      }
      .bottom-btns {
        .btn {
          border-color: $tpbThemeColor;
          background-color: $tpbThemeColor;
        }
      }
    }
    .thai-pricing-list,.quotation,.order {
      .van-loading__spinner {
        color: $tpbThemeColor !important;
      }
      .fixed-bottom {
        .comp-btn.can-comp {
          background-color: $tpbThemeColor;
        }
        .clear-btn {
          color: $tpbThemeColor;
          border-color: $tpbThemeColor;
        }
      }
      .view-more {
        color: $tpbThemeColor;
      }
    }
    .van-loading__spinner {
      color: $tpbThemeColor !important;
    }
    .list-con,.simp-list-con {
      .block {
        .tit-line,.tit-line-v2 .p-m {
          color: $tpbThemeColor;
        }
        .btn-line .view-btn {
          color: $tpbThemeColor;
        }
        .bo-btns .btn.select-btn {
          background-color: $tpbThemeColor;
        }
      }
    }
    .filter-page-con {
      .fil-tit {
        color: $tpbThemeColor;
      }
      .bo-btns .btn.apply-btn {
        background-color: $tpbThemeColor;
      }
    }
    .thai-compare-con {
      .car-con {
        background-color: $tpbThemeColor;
      }
      .tools-lines .t-btn {
        background-color: $tpbThemeColor;
        border-color: $tpbThemeColor;
        &.save {
          background-color: white;
          color: $tpbThemeColor;
        }
      }
    }
    .selecting-con {
      .package-tit-con .van-cell .van-icon-arrow {
        color: $tpbThemeColor;
      }
      .benefit-item .benefit-config .fu-form-select .van-icon {
        color: $tpbThemeColor;
      }
      .block {
        .imp-font {
          color: $tpbThemeColor;
        }
        .van-radio {
          .van-radio__icon--checked {
            .van-icon {
              border-color: $tpbThemeColor !important;
              background-color: $tpbThemeColor !important;
            }
          }
        }
      }
      .bottom-btns .btn {
        background: $tpbThemeColor;
      }
    }
    .package-tit-con {
      .van-cell .van-icon-arrow {
        color: $tpbThemeColor;
      }
    }
    .th-next-btn {
      border-color: $tpbThemeColor !important;
      background-color: $tpbThemeColor !important;
    }
    .fu-form-min {
      .van-radio__icon.van-radio__icon--checked::before {
        background-color: $tpbThemeColor;
      }
      .van-cell--required::before {
        color: $tpbThemeColor;
      }
      .van-field__body .van-field__right-icon {
        color: $tpbThemeColor;
      }
      &.fu-form-mini-theme .van-collapse-item__title .van-cell__title {
        color: $tpbThemeColor;
      }
      &.fu-form-mini-theme .fu-form-min-object .fu-form-min-tit span, .fu-form-mini-theme .fu-form-min-array .fu-form-min-tit span {
        color: $tpbThemeColor;
      }
      .van-checkbox__icon--checked .van-icon {
        border-color: $tpbThemeColor !important;
        background-color: $tpbThemeColor !important;
      }
      // .van-field__error-message {
      //   color: $tpbThemeColor;
      // }
    }
    .check-boxes .van-checkbox__icon--checked .van-icon {
        border-color: $tpbThemeColor !important;
        background-color: $tpbThemeColor !important;
    }
    .fu-form-details-v2 .van-collapse-item .van-icon-arrow {
      color: $tpbThemeColor;
    }
    .thai-preview-con .confirm-btn {
        border-color: $tpbThemeColor !important;
        background-color: $tpbThemeColor !important;
    }
    .order-detail-con {
      .block {
        .line .r-icon {
          color: $tpbThemeColor;
        }
        .label {
          color: $tpbThemeColor;
        }
        &.insurance-info {
          .label {
            color: $tpbThemeColor;
          }
        }
      }
      .bott-con {
        .line .right {
          background-color: $tpbThemeColor;
        }
        .expires-time {
          color: $tpbThemeColor;
        }
      }
      .net-pay {
        .n-r {
          .van-switch--on {
            background-color: $tpbThemeColor !important;
          }
        }
      }
      .net-pay-dia {
        .net-tit {
          color: $tpbThemeColor;
        }
        .net-btns {
          .n-r {
            border-color: $tpbThemeColor;
            background-color: $tpbThemeColor;
          }
          .n-l {
            color: $tpbThemeColor;
            border-color: $tpbThemeColor;
          }
        }
      }
      .van-loading__spinner {
        color: $tpbThemeColor!important;
      }
    }
    .th-register, .th-register-success {
      .reg-list-btn, .reg-btn {
        background-color: $tpbThemeColor;
      }
      .th-lang {
        .lang-item {
          color: $tpbThemeColor;
          &.active {
            color: white;
            background: $tpbThemeColor;
          }
        }
      }
      // .reg-tips .red { color: $tpbThemeColor; }
      .tit {
        color: $tpbThemeColor;
      }
      .btns-con .open-btn {
        color: $tpbThemeColor;
      }
      .p-b-2 {
        .tit {
          color: $tpbThemeColor;
        }
      }
    }
    .f-color {
      color: $tpbThemeColor !important;
    }
  }
}
.body-is-TPB {
  .fu-select-list-cell.active {
    color: $tpbThemeColor !important;
  }
  .van-tabs__line {
    background-color: $tpbThemeColor !important;
  }
  .van-goods-action {
    .van-button {
      background: $tpbThemeColor !important;
      border-color: $tpbThemeColor !important;
    }
  }
  .fu-min-form-address-cell.active {
    .van-cell__title > span {
      color: $tpbThemeColor !important;
    }
    .van-icon-success {
      color: $tpbThemeColor !important;
    }
  }
  .inspection-dialog {
    .van-dialog__message {
      .van-icon {
        color: $tpbThemeColor !important;
      }
      .tit {
        color: $tpbThemeColor !important;
      }
    }
    .van-dialog__footer {
      .van-goods-action-button--first {
        color: #fff !important;
        background-color: $tpbThemeColor !important;
      }
      .van-goods-action-button--last {
        color: $tpbThemeColor;
        background-color: #fff !important;
      }
    }
  }
  .th-reg-con .van-cascader {
    .van-cascader__option--selected {
      color: $tpbThemeColor;
    }
  }
}
.body-isBigScreen {
  .van-popup--bottom {
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  .van-collapse-item__title--disabled {
    cursor: auto;
  }
}
</style>
