import en from './en'
import vn from './vn'
import th from './th'
import id from './id'

export default {
  en: en,
  vi: vn,
  th: th,
  id: id
}
