export default {
  data() {
    return {
      isFixedBottomHidden: false,

      // 记录原始高度
      originalHeight: document.documentElement.clientHeight || document.body.clientHeight,
      // 实时高度
      screenHeight: document.documentElement.clientHeight || document.body.clientHeight
    }
  },
  mounted() {
    this.getScreenHeight()

    window.addEventListener('resize', this.getScreenHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getScreenHeight)
  },
  methods: {
    getScreenHeight() {
      this.screenHeight = document.documentElement.clientHeight || document.body.clientHeight
      const diffH = this.originalHeight - this.screenHeight

      // 屏幕原始高度大于可视高度 且差距超过120 的时候 隐藏固定在底部的fixed元素
      this.isFixedBottomHidden = !!((this.originalHeight > this.screenHeight && diffH > 120))
    }
  }

}
