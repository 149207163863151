/**
 * @Author: huangjiaquan
 * @Date: 2023-04-20 10:02:29
 * @LastEditTime: 2023-05-30 17:35:51
 * @LastEditors: huangjiaquan
 * @FilePath: /fusePro-web/src/i18n/langs/modules/vn/new.js
 */
export default {
  paymentAmount: 'Số tiền thanh toán',
  selectCoverage: 'Chọn quyền lợi bảo hiểm',
  commissionBonus: 'Tiền thưởng',
  receivedBonus: 'Tiền thưởng nhận được',
  totalCommissionTips: 'Tổng hoa hồng thu được bao gồm hoa hồng cơ bản và phí hỗ trợ',
  premiumDetail: 'Chi tiết phí bảo hiểm',
  coverageName: 'Quyền lợi',
  sumInsured: 'Số tiền bảo hiểm',
  income: 'Thu nhập'
}
