const data = {
  'policyStatus': {
    0: '-',
    1: 'Inactive',
    2: 'Effective',
    3: 'Inoperative',
    4: 'Surrender'
  },
  'paymentStatus': {
    1: 'Unpaid',
    2: 'Pending',
    3: 'Paid',
    4: 'Refunded'
  },
  'orderStatus': {
    1: 'Not Submit',
    2: 'Preliminary Review',
    3: 'Underwriting',
    4: 'Approval',
    5: 'Declined',
    6: 'Invalid'
  },
  'comparisonStatus': {
    2: 'To Be Confirmed',
    3: 'Quoted',
    4: 'Expired'
  },
  'comparisonStatusLocal': {
    2: 'Đang xác nhận',
    3: 'Đã tạo báo giá',
    4: 'Hết hạn'
  }
}

export function codeMap(key) {
  return data
}
