import Vue from 'vue'
import * as fundebug from 'fundebug-javascript'
import FundebugVue from 'fundebug-vue'

// 生产环境
const PROD_ENV_API_KEY = '1ac859b9805d5e42df09de1d49b34287bb39c87f28755257870243a97a764f3a'
// 其他线上环境
const OTHER_ENV_API_KEY = '33973d085652a192bd2c28c400eba852ae228f47d496faccd3b68527e1860baf'

fundebug.init({
  apikey: process.env.VUE_APP_ENV == 'prod' ? PROD_ENV_API_KEY : OTHER_ENV_API_KEY,
  // silentResource: true, // 不需要监控资源加载错误
  filters: [
    {
      type: /^unhandledrejection$/
    },
    {
      name: /^uncaught error$/,
      message: /ResizeObserver/
    }
  ]
})

export default {
  run() {
    // 生存环境引入报错监听，并打印日记
    if (['prod', 'uat'].includes(process.env.VUE_APP_ENV)) {
      new FundebugVue(fundebug).installVueErrorHandler(Vue)
    } else {
      console.log('Application is running is development Env ')
    }
  }
}

