import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './langs'
import Dao from '@/utils/dao'
import { Locale } from 'vant'

Locale.add(messages)

const lang = Dao.get('lang') || 'en'

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: lang,
  messages: messages
})
Locale.use(lang, messages.lang)

export default i18n
