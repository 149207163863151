export default {
  paymentAmount: 'Payment Amount',
  selectCoverage: 'Select Coverage',
  commissionBonus: 'Bonus',
  receivedBonus: 'Received Bonus',
  totalCommissionTips: 'Total commission includes Basic commission and Supporting fee',
  premiumDetail: 'Premium Detail',
  coverageName: 'Coverage Name',
  sumInsured: 'Sum insured',
  income: 'Income'
}
