import { Session } from '@/utils/storage'

const lang = Session.get('appInfo') ? Session.get('appInfo').lan || 'vi' : 'vi'
const state = {
  lang: lang,
  currency: 'VND',
  timeZone: 7,
  region: 'VN',
  agentUid: '',
  deviceInfo: {},
  desktopConfig: { isBigScreen: false }
}

const mutations = {
  CHANGE_LANG: (state, value) => {
    state.lang = value
    Session.set('lang', value)
  },
  CHANGE_AGENTUID: (state, value) => {
    state.agentUid = value
  },
  CHANGE_CURRENCY: (state, value) => {
    state.currency = value
    Session.set('currency', value)
  },
  CHANGE_TIMEZONE: (state, value) => {
    state.timeZone = value
    Session.set('timeZone', value)
  },
  CHANGE_REGION: (state, value) => {
    state.region = value
  },
  SET_DEVICE_INFO: (state, value) => {
    state.deviceInfo = value
    Session.set('deviceInfo', value)
  },
  SET_DESKTOP_CONFIG: (state, value) => {
    state.desktopConfig = value
    Session.set('desktopConfig', value)
  }
}
const actions = {
  changeLang({ commit }, data) {
    commit('CHANGE_LANG', data)
  },
  changeAgentUid({ commit }, data) {
    commit('CHANGE_AGENTUID', data)
  },
  changeCurrency({ commit }, data) {
    commit('CHANGE_CURRENCY', data)
  },
  changeTimeZone({ commit }, data) {
    commit('CHANGE_TIMEZONE', data)
  },
  changeRegion({ commit }, data) {
    commit('CHANGE_REGION', data)
  },
  setDeviceInfo({ commit }, data) {
    commit('SET_DEVICE_INFO', data)
  },
  setDesktopConfig({ commit }, data) {
    commit('SET_DESKTOP_CONFIG', data)
  }

}

export default {
  namespaced: true,
  state,

  mutations,
  actions
}
