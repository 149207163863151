const state = {
  // 路由跳转的loading
  routerLoading: false
}

const mutations = {
  CHANGE_ROUTER_LOADING: (state, value) => {
    state.routerLoading = value
  }
}
const actions = {
  changeRouterLoading({ commit }, data) {
    commit('CHANGE_ROUTER_LOADING', data)
  }
}

export default {
  namespaced: true,
  state,

  mutations,
  actions
}
