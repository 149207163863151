// import enUS from 'vant/lib/locale/lang/en-US'

const th = {
  vn: 'VN',
  en: 'EN',
  ok: 'Ok',
  byAEC: 'บริษัท เออีซี อินชัวร์รันส์ โบรคเกอร์ จำกัด',
  ePolicy: 'กรมธรรม์อิเล็กทรอนิกส์',
  close: 'ปิดหน้าต่างนี้',
  cancel: 'ยกเลิก',
  confirm: 'ยืนยัน',
  select: 'เลือก',
  clearAll: 'ล้างทั้งหมด',
  compare: 'เปรียบเทียบ',
  addtoCompare: 'เพิ่มเพื่อเปรียบเทียบ',
  remove: 'ยกเลิก',
  compulsory: 'พ.ร.บ.',
  netPayment: 'Net Payment',
  superNetPayment: 'Super Net Payment',
  superAllBonusPoint: 'จำนวนเงินทั้งหมดที่ได้รับจากโบนัสและคะแนนของคำสั่งซื้อนี้จะถูกนำไปใช้โดยหักจากจำนวนเงินที่ต้องชำระทั้งหมด',
  allBonusPoint: 'จำนวนเงินทั้งหมดที่ได้รับจากโบนัสและคะแนนของคำสั่งซื้อนี้จะถูกนำไปใช้โดยหักจากจำนวนเงินที่ต้องชำระทั้งหมด',
  confirmToUsed: 'ยืนยันการใช้ Net Payment?',
  confirmToUsedSuper: 'ยืนยันการใช้ Super Net Payment ใช่หรือไม่?',
  byChoosing: 'โดยการเลือก Net Payment คุณตกลงและเข้าใจว่าโบนัสและคะแนนที่ได้รับจากคำสั่งซื้อนี้จะถูกนำมาใช้และหักออกจากจำนวนเงินที่ต้องชำระโดยตรง',
  byChoosingSuper: 'การเลือก Super Net Payment แสดงว่าคุณตกลงและเข้าใจว่าโบนัสและคะแนนที่ได้รับจากคำสั่งซื้อนี้จะถูกนำไปใช้และหักออกจากจำนวนเงินที่ต้องชำระโดยตรง',
  netConfirm: 'ยืนยัน',
  netCancel: 'ยกเลิก',
  dutyDesc: 'เงื่อนไขการรับประกันขึ้นอยู่กับข้อมูลกรมธรรม์ที่บริษัทประกันภัยจัดทำขึ้น',
  register: {
    mobileEmpty: 'กรุณากรอกหมายเลขโทรศัพท์ของท่าน',
    mobileInvalid: 'ขออภัย หมายเลขโทรศัพท์นี้ไม่สามารถใช้งานได้',
    codeTipS: 'กรุณากรอกรหัส4หลักที่เราทำการส่งไปยัง',
    codeTipE: 'ผ่านข้อความ SMS',
    codeFail: 'เราพบข้อผิดพลาดขณะทำการส่งรหัสยืนยันตัวตนให้กับท่าน กรุณาลองใหม่อีกครั้งในอีก 3 นาที',
    thCodeFail: 'กรุณากรอกเบอร์มือถือที่ถูกต้องและลองใหม่อีกครั้ง',
    codeEmpty: 'รหัสยืนยันตัวตนต้องมี 4 หลัก',
    thCodeEmpty: 'รหัส OTP ต้องมี 4 หลัก',
    codeInvalid: 'หมายเลขโทรศัพท์หรือรหัสยืนยันตัวตนไม่ถูกต้อง',
    thCodeInvalid: 'หมายเลขโทรศัพท์หรือรหัส OTP ไม่ถูกต้อง',
    nameEmpty: 'กรุณากรอกชื่อเล่นของท่าน',
    nameInvalid: 'ชื่อเล่นควรจะเริ่มต้นด้วยตัวอักษร และสามารถผสมกันได้ระหว่างตัวอักษร ตัวเลข เครื่องหมาย "_" และช่องว่าง',
    passwordEmpty: 'กรุุณาตั้งค่ารหัสผ่านสำหรับบัญชีของท่าน',
    passwordInvalidStyle: 'อนุญาตให้ใช้เฉพาะตัวอักษร ตัวเลข และอักขระพิเศษ (ยกเว้นช่องว่าง)',
    passwordInvalidLength: 'กรุณาตั้งค่ารหัสผ่านอย่างน้อย 6 ตัวอักษร',
    passwordEasy: 'รหัสผ่านนี้ไม่ซับซ้อนและคาดเดาได้ง่ายเกินไป',
    send: 'ส่ง',
    reSend: 'ส่งอีกครั้ง',

    voiceTipS: 'We will call the phone number ',
    voiceTipE: ', please kindly answer it.',

    title: 'สมัคร Fuse',
    labelMobile: 'หมายเลขโทรศัพท์',
    labelCode: 'รหัสยืนยันตัวตน',
    thOTPCode: 'กรอกรหัส OTP',
    thGetOTP: 'ขอรหัส OTP',
    labelName: 'ชื่อนามสกุล',
    thLabelName: 'ชื่อนามสกุล',
    emailAddress: 'อีเมล์',
    labelPass: 'รหัสผ่าน',
    laConfirmPass: 'ยืนยันรหัสผ่าน',
    voice: 'Get Voice Verification Code',

    // thaiPHPhone: 'ตัวอย่างเช่น 0999999999',
    thaiPHPhone: 'กรุณากรอกหมายเลขโทรศัพท์มือถือ10หลัก',
    placeholderMobile: 'ตัวอย่างเช่น 77998xxxx',
    placeholderCode: 'ตัวอย่างเช่น 6128',
    placeholderName: 'ตัวอย่างเช่น Tuan',
    placeholderPass: 'การผสมกันของตัวอักษรและตัวเลข 6-20 ตัว',
    thPlacePass: 'อย่างน้อย 6 ตัวอักษร',
    sign: 'สมัคร',
    tip: 'ในการสมัคร ท่านตกลงตาม',
    and: 'และ',
    tc: 'ข้อกำหนดและเงื่อนไข',
    ps: 'Private Statement',
    pp: 'นโยบายความเป็นส่วนตัว',
    copy: 'Fuse Tech Company Limited',

    confirmPwd: 'รหัสผ่านไม่ตรงกัน',
    successTit: 'ยินดีด้วย',
    sucDesc: 'กรุณาเปิดแอพมือถือของเราเพื่อยืนยันบัญชีของคุณ',
    OpenFusePro: 'เปิด FUSE Pro',
    returnToHomepage: 'กลับไปที่หน้าแรก',
    openDesc: 'ยังไม่มี FUSE Pro? คุณจะถูกเปลี่ยนเส้นทางไปยัง App Store ของคุณโดยแตะที่ปุ่มด้านบน',
    successIOS: 'Download on the',
    successAndroid: 'Get it',

    registered: 'หมายเลขโทรศัพท์นี้ถูกใช้งานแล้ว',
    needHelp: 'พบปัญหาในการสมัคร? ติดต่อเรา',
    telephone: 'หมายเลขติดต่อ',
    email: 'อีเมล',
    earnBonus: 'ลงทะเบียนฟรีทันทีเพื่อรับรางวัล',
    registerTit: 'ร่วมเป็น Partner กับ Fuse Pro',
    registerTPBTit: 'ร่วมเป็น Partner',
    referrerNickname: 'ชื่อเล่นของผู้แนะนำ',
    referrerNicknameTips: `ชื่อเล่นของผู้แนะนำท่านเข้าสู่แพลตฟอร์มเรา`,
    signInNow: 'เข้าสู่ระบบตอนนี้',
    goToMarketplace: 'ไปที่ตลาด',
    aeciTab: 'คุณสร้างบัญชีของAECI x TABสำเร็จแล้ว',
    havingTrouble: 'พบปัญหาในการสมัคร?',
    contactUs: ' ติดต่อเรา',
    phoneCall: 'หมายเลขติดต่อ',
    line: 'LINE',
    copyright: 'บริษัท ฟิวส์ เทค โซลูชัน(ประเทศไทย) จำกัด สงวนลิขสิทธิ์',
    pleaseSel: 'โปรดเลือก',
    province: 'จังหวัดและอำเภอ',
    provinceEmpty: 'กรุณาเลือกจังหวัดและอำเภอ',
    outOfDate: 'หน้านี้หมดอายุแล้ว',
    forAssistance: 'กรุณาติดต่อเพื่อขอความช่วยเหลือ',
    tel: 'โทร.'
  },
  firstLogin: {
    title: 'ลงทะเบียนหมายเลขโทรศัพท์',
    formDesc: 'กรุณาใส่หมายเลขโทรศัพท์ของคุณและรหัสยืนยัน',
    Oops: 'มีบางอย่างผิดพลาด',
    codeTo: 'โปรดตรวจสอบรหัสยืนยันที่ส่งไป',
    congratulations: 'ยินดีด้วย!',
    congraDesc: 'คุณได้ลงทะเบียนหมายเลขโทรศัพท์เรียบร้อยแล้ว เราได้ส่งรหัสผ่านเริ่มต้นไปที่อีเมลของคุณ',
    alreadyRegistered: 'คุณได้ลงทะเบียนหมายเลขโทรศัพท์ของคุณแล้ว',
    pageOutOfDate: 'หน้านี้หมดอายุ'
  },
  improvementText: {
    chooseCmi: 'ไม่สามารถใช้การชำระแบบผ่อนได้หากรวม พ.ร.บ. ',
    SystemUnderMaintenanceTit: 'ระบบอยู่ระหว่างการปรับปรุง',
    SystemUnderMaintenanceDesc: `เรากำลังปรับปรุงเพื่อเพิ่มประสบการณ์ในการใช้แอปพลิเคชันของคุณ ในช่วงนี้ ฟังก์ชันบางอย่างอาจใช้งานไม่ได้ชั่วคราว หากคุณต้องการความช่วยเหลือ กรุณาติดต่อเรา`
  },
  payAbout: {
    signDia: {
      tit: 'โปรดลงนามเพื่อดำเนินการต่อ',
      content: 'การชำระเงินแบบNet ใช้ได้เฉพาะพาร์ทเนอร์ที่ได้ลงนามข้อตกลงการเป็นหุ้นส่วนแล้วเท่านั้น',
      superContent: 'การชำระเงินแบบSuper Net ใช้ได้เฉพาะพาร์ทเนอร์ที่ได้ลงนามข้อตกลงการเป็นหุ้นส่วนแล้วเท่านั้น',
      later: 'ภายหลัง',
      signNow: 'ลงนามตอนนี้'
    },
    payNow: 'ชำระเงินเดี๋ยวนี้',
    payNowDesc: 'การออกกรมธรรม์จะเริ่มขึ้นหลังจากที่ได้รับการชำระค่าเบี้ยประกันภัย',
    payLater: 'ชำระเงินภายหลัง',
    payLaterDesc: 'กรุณาชำระค่าเบี้ยประกันภัยหลังจากที่ได้รับการพิจารณารับประกันภัยเป็นที่เรียบร้อย',
    apply: 'ถูกต้อง',
    discountToCustomer: 'ส่วนลดสำหรับลูกค้า',
    discountInfo: 'ข้อมูลการให้ส่วนลด',
    discount: 'ส่วนลด',
    discountTit: 'คุณต้องการให้ส่วนลดแก่ลูกค้าจำนวนเท่าไหร่',
    discountDesc: 'ส่วนลดสูงสุดที่สามารถให้แก่ลูกค้าท่ากับ'
  },
  requireMore: {
    title: 'โปรดระบุรายละเอียดเพิ่มเติมด้านล่าง',
    defaultDesc: 'เจ้าหน้าที่ของเราจะติดต่อคุณเพื่อขอข้อมูลเพิ่มเติมที่จำเป็น',
    quotationSlip: 'สลิปใบเสนอราคา'
  },
  oic: {
    signature: 'ลายเซ็น',
    consentTit: 'ข้าพเจ้าตกลงทำประกันภัย',
    cD1: 'ตามเงื่อนไข',
    cD2: '(a) ข้าพเจ้าขอรับรองว่า ข้าพเจ้ามีสุขภาพแข็งแรง ไม่มีส่วนใดส่วนหนึ่งพิการหรือทุพพลภาพ และไม่มีโรคประจำตัวเรื้อรัง โรคร้ายแรง ไม่เป็นผู้วิกลจริต ไม่มีอาการหรือโรคที่เกี่ยวเนื่องกับภาวะทางจิต โรคพิษสุราเรื้อรัง และไม่เป็นผู้ติดสารเสพติด และขอรับรองว่า คำแถลงตามรายการข้างบนเป็นความจริง และให้ถือเป็นส่วนหนึ่งของสัญญาประกันภัยระหว่างข้าพเจ้าและบริษัท บริษัท กรุงเทพประกันภัย จำกัด (มหาชน)',
    cD3: '(b) ข้าพเจ้ารับทราบข้อกำหนดและเงื่อนไขการรับประกันภัยตามแผนความคุ้มครองที่ข้าพเจ้าได้เลือกไว้ และตกลงขอเอาประกันภัยภายใต้ข้อกำหนด และเงื่อนไขการประกันภัยดังกล่าว',
    cD4: '(c) ข้าพเจ้ายินยอมให้บริษัทฯ จัดเก็บใช้ และเปิดเผย ข้อเท็จจริงเกี่ยวกับสุขภาพและข้อมูลของข้าพเจ้าต่อสำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย เพื่อประโยชน์ในการกำกับดูแลธุรกิจประกันภัย',
    cD5: '(d) ข้าพเจ้าขอรับรองว่าข้อมูลที่แจ้งให้ทราบเป็นความจริงทุกประการ และตกลงทำประกันภัยตามทุนประกันภัย ดังกล่าวข้างต้น และ ""ข้าพเจ้าขอรับรองและยืนยันว่า ข้าพเจ้าไม่เคยมีประวัติการกระทำผิดกฎหมายป้องกันและปราบปรามการฟอกเงิน หรือ กฎหมายป้องกันและปราบปรามการสนับสนุนทางการเงินแก่การก่อการร้าย และการแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูง หรือกฎหมายอื่นที่เกี่ยวข้อง เช่น กฎหมายเกี่ยวกับยาเสพติดให้โทษ และข้าพเจ้าขอรับรองว่าข้อมูลรายละเอียดข้างต้นเป็นความจริงทุกประการ',
    cD6: 'คำเตือน ของสำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย (คปภ.) ให้ตอบคำถามข้างต้นตามความเป็นจริงทุกข้อ หากผู้ขอเอาประกันภัยปกปิดข้อความจริง หรือแถลงข้อความอันเป็นเท็จจะมีผลให้สัญญานี้ตกเป็นโมฆะ ซึ่งบริษัทมีสิทธิบอกล้างสัญญาประกันภัยได้ ตามประมวลกฎหมายแพ่งและพาณิชย์ มาตรา 865',
    cD7: 'ข้าพเจ้ายินยอมให้บริษัทใช้หมายเลขโทรศัพท์ หมายเลขโทรศัพท์มือถือ และ/หรืออีเมล์ ที่ให้ไว้ข้างต้นนี้ ในการแจ้งผลที่ข้าพเจ้าได้รับการอนุมัติหรือข่าวสารอื่นๆ การเสนอขายผลิตภัณฑ์ของบริษัท และข้าพเจ้าจะแจ้งให้บริษัททราบทันทีหากมีการเปลี่ยนแปลงใดๆ',
    cD8: 'ฉันยินยอมให้รวบรวม ใช้ หรือเปิดเผยข้อมูลด้านสุขภาพ',
    cD9: 'ข้าพเจ้ายินดีรับข้อมูลข่าวสาร การสื่อสาร สิทธิประโยชน์ โปรโมชั่นพิเศษ และกิจกรรมต่างๆ ของบริษัทฯ บริษัท กรุงเทพประกันภัย จำกัด  และยินยอมให้บริษัทฯ เก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อการดำเนินการดังกล่าวได้ '
  },
  aeci: {
    b1Desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    b2Tit: 'ข้อกำหนดและเงื่อนไข',
    b2Desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    b3Tit: 'ข้อสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไข',
    b3Desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    b4Tit: 'คำถามที่พบบ่อย',
    b4Q1: 'Lorem ipsum dolor sit amet',
    b4Q1Desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    b4Q2: 'Lorem ipsum dolor sit amet',
    b4Q2Desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    b4Q3: 'Lorem ipsum dolor sit amet',
    b4Q3Desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    footerDesc: 'I have read and agree to allow FUSE Tech Thai to generate the account'
  },
  successed: 'สำเร็จ',
  chooseSumInsured: 'เลือกทุนประกันที่คุณต้องการ',
  theDeductibleIs: 'จำนวนเงินที่ลูกค้าต้องจ่ายเป็นลำดับแรกก่อนที่บริษัทประภัยจะจ่ายส่วนที่เหลือ',
  selectPlan: 'เลือกแผน',
  viewDetail: 'ดูรายละเอียด',
  pSYPP: 'กรุณาเลือกแผนที่ท่านต้องการ',
  numProductsFound: 'ผลิตภัณฑ์',
  noPF: 'ไม่พบผลิตภัณฑ์ กรุณาเลือกการตั้งค่าค้นหาใหม่',
  loadingNoPF: 'กำลังดาวน์โหลดผลิตภัณฑ์ กรุณารอสักครู่',
  thRfq: {
    declinebtn: 'ปฎิเสธ',
    getQuotation: 'เช็คเบี้ย',
    negotiationRequest: 'ต้องการต่อรอง',
    quoteDecline: 'ขอเสนอถูกปฎิเสธ',
    decline: 'เหตุผลในการปฎิเสธ',
    dRIsRequired: `จำเป็นต้องกรอกเหตุผลที่ปฎิเสธ`,
    VehicleNotFound: 'ถ้าคุณหารถยนต์ยี่ห้อหรือรุ่นที่ต้องการ',
    noPlanFound: 'ไม่พบแผนการประกันภัยที่ต้องการ ',
    requestQuote1: 'ขอใบเสนอราคา',
    requestQuote2: 'จากบริษัทประกันภัย',
    planNotFound: `ถ้าคุณไม่พบแผนประกันภัยที่ต้องการ`,
    letUsKnow: `กรุณาแจ้งเรา`,
    foundBetter: 'หากคุณได้รับเงื่อนไขที่ดีกว่า',
    letUsKnowMatch: `กรุณาแจ้งเรา`,
    succRequest: 'คำขอของท่านได้รับการดำเนินการเรียบร้อยแล้ว',
    weAreGlad: 'บริษัทมีความยินดีที่ได้รับใช้ท่าน และจะดำเนินการจัดหาใบเสนอราคาให้แก่ท่านโดยเร็วที่สุด',
    backToHome: 'กลับไปหน้าหลัก',
    viewQuote: 'ดูข้อเสนอ',
    yourSubmission: 'ข้อเสนอของท่านกำลังได้รับการพิจารณา',
    yourSubMissionDesc: 'ข้อเสนอของท่านอยู่ระหว่างการดำเนินการภายใน 1-3 วันทำการ กรุณติดต่อเราหากต้องการความช่วยเหลือเพิ่มเติม',
    call: 'โทรหาเรา',
    close: 'ปิด',
    generalInfo: 'ข้อมูลทั่วไป',
    rfqId: 'ใบคำขอเลขที่',
    createdTime: 'วันที่ทำใบคำขอ',
    status: 'สถานะ',
    email: 'อีเมล',
    quotes: 'ใบเสนอราคา',
    requireMoreData: 'ขอข้อมูลเพิ่มเติม',
    requireMoDesc: 'กรุณาให้ข้อมูลเพิ่มเติมเพื่อการเสนอราคาที่แม่นยำมากขึ้น',
    qsId: 'ใบเสนอราคาเลขที่',
    quotedPrice: 'ราคาที่นำเสนอ',
    expiryDate: 'วันสิ้นสุดความคุ้มครอง',
    quoteDetail: 'รายละเอียดใบเสนอราคา',
    days: 'วัน',
    hours: 'ชั่วโมง',
    minutes: 'นาที',
    seconds: 'วินาที',
    processing: 'อยู่ระหว่างดำเนินการ',
    quoted: 'เสนอราคาเรียบร้อยแล้ว',
    declined: 'ถูกปฎิเสธ',
    expired: 'สิ้นสุดการมีผลบังคับใช้',
    accepted: 'ได้รับการยอมรับ',
    confirmed: 'ยืนยันแล้ว',
    toBeConfirmed: 'รอชำระเบี้ย',
    needMoreData: 'ต้องการข้อมูลเพิ่มเติม',
    kindlyRemind: 'ขออนุญาตแจ้งว่าข้อเสนอนี้กำลังจะหมดอายุในวันที่',
    bonus: 'โบนัส',
    specialBonusFrom: 'โบนัสพิเศษ',
    commission: 'โบนัส',
    personalIncomeTax: 'ภาษีรายได้บุคคลธรรมดา',
    receivedCommission: 'ผลตอบแทนที่ได้รับ',
    thisCommissionFigure: 'ผลตอบแทนที่คำนวณได้ไม่ตรงกับผลตอบแทนที่ได้รับ',
    premium: 'เบี้ยประกันภัย',
    totalPremium: 'เบี้ยประกันภัยรวม'
  },
  sumInsuredAmount: 'ทุนประกันภัย',
  deductibleAmount: 'ค่าความเสียหายส่วนแรก',
  thirdPartyAmount: 'ความคุ้มครองต่อบุคคลภายนอก',
  filter: 'ตั้งค่าค้นหา',
  insuranceType: 'ประเภทประกันภัย',
  repairType: 'ประเภทงานซ่อม',
  insuranceCompany: 'บริษัทประกันภัย',
  all: 'ทั้งหมด',
  allInsuranceCompany: 'เลือกบริษัทประกันภัยทั้งหมด',
  allTypes: 'ประเภทประกันภัยทั้งหมด',
  class1: 'ประกัน ชั้น 1',
  class2: 'ประกัน ชั้น 2',
  class3: 'ประกัน ชั้น 3',
  class2Plus: 'ประกัน ชั้น 2+',
  class3Plus: 'ประกัน ชั้น 3+',
  priceRange: 'ขอบเขตเบี้ยประกัน',
  allPrice: 'ราคาทั้งหมด',
  THB: 'บาท',
  reset: 'รีเซ็ต',
  applyFilters: 'ใช้ค่าค้นหานี้',
  repairCenter: 'ศูนย์ซ่อมรถยนต์',
  garage: 'ซ่อมอู่',
  dealer: 'ซ่อมห้าง',
  earn: 'รับ',
  commission: 'โบนัส',
  specialBonus: 'แต้มพิเศษ',
  points: 'คะแนน',
  totalSpecialBonus: 'แต้มพิเศษทั้งหมด',
  planDetail: 'รายละเอียดแผน',
  totalPremium: 'เบี้ยประกันรวม',
  newSumInsured: 'ทุนประกันภัยใหม่',
  dedAmount: 'Deductible Amount',
  includeCompulsoryMotor: 'รวม พ.ร.บ.',
  bankTransfer: 'โอนผ่านธนาคาร',
  next: 'ถัดไป',
  uploadProof: 'อัปโหลดหลักฐาน',
  duty: {
    unit2: 'วัน',
    unit3: 'ปี',
    unit4: 'Disability',
    unit5: 'Confinement',
    unit6: 'Pregnancy',
    unit7: 'ครั้ง',
    unit8: 'คน'
  },
  loadingProductDesc: 'ผลิตภัณฑ์ที่เราแนะนำสำหรับคุณ',
  viewMoreOptions: 'ดูตัวเลือกเพิ่มเติม',
  free: 'ไม่มีค่าใช้จ่าย',
  yes: 'ส่ง',
  no: 'ไม่ส่ง',
  yes2: 'รวม',
  no2: 'ไม่รวม',
  orderDetail: 'รายละเอียดการสั่งซื้อ',
  orderInfomation: 'ข้อมูลการสั่งซื้อ',
  insuranceInformation: 'ข้อมูลประกันภัย',
  orderNo: 'เลขที่สั่งซื้อ',
  orderDate: 'วันที่สั่งซื้อ',
  paymentProof: 'หลักฐานการชำระเงินที่รอการตรวจสอบ',
  paymentPic: 'หลักฐานการชำระเงินสั่งซื้อ',
  productName: 'ชื่อผลิตภัณฑ์',
  orderGenerated: 'กำลังสร้างคำสั่งซื้อ โปรดรอสักครู่',
  status: 'สถานะ',
  pay: 'ชำระเงิน',
  policyNo: 'เลขกรมธรรม์',
  insuredPeriod: 'ระยะเวลากรมธรรม์',
  theInsured: '​ผู้เอาประกันภัย',
  quoteDate: 'วันที่เสนอ',
  validUntil: 'ใช้ได้จนถึง',
  save: 'บันทึก',
  share: 'แชร์',
  quotationFrom: 'ข้อเสนอจากบริษัทประกัน',
  thisQuotationTable: 'ข้อเสนอนี้ใช้สำหรับการอ้างอิงเท่านั้นและขึ้นอยู่กับความคุ้มครองที่เกิดขึ้นจริงเมื่อซื้อ © Fuse Tech Solution (Thailand) Co., Ltd.',
  thisQ1: 'หมายเหตุ',
  thisQ2: '1. เอกสารฉบับนี้เป็นเพียงใบเสนอราคาเท่านั้นบริษัทฯขอสงวนลิขสิทธิ์ในการยกเลิก ปรับปรุง เปลี่ยนแปลงใดๆได้',
  thisQ3: '2. ฟิวส์โปร เป็นเพียงระบบขายประกันภัย โดย บริษัท เออีซี อินชัวรันส์ โบรกเกอร์ จำกัด',
  thisQ4: '3. เงื่อนไขการรับประกันเป็นไปตามบริษัทประกันภัยกำหนด',
  thisQ5: '4. ผู้ซื้อควรทำความเข้าใจในรายละเอียดความคุ้มครองและเงื่อนไขก่อนการตัดสินใจการทำประกันภัย',
  thisQ6: '5. บริษัท เออีซี อินชัวรันส์ โบรกเกอร์ จำกัด เป็นเพียงนายหน้าประกันวินาศภัยเท่านั้นเลขที่ใบอนุญาตประกันวินาศภัย ว00019/2563',
  proofToast: 'เนื่องจากระยะเวลาการชำระเงินที่ถูกต้องของคำสั่งซื้อหมดอายุแล้ว คุณจึงไม่สามารถอัปโหลดหลักฐานการชำระเงินได้อีกต่อไป',
  submit: 'ส่ง',
  order: {
    statusMap: {
      1: 'ดำเนินการ', // 正在处理中
      2: 'สมบูรณ์', // 完成，当订单中所有投保单状态变成终态或生成保单，则订单状态变为Completed
      3: 'ปฏิเสธ'
    },
    orderExpires: 'คำสั่งซื้อจะหมดอายุใน',
    orderDeclined: 'ปฏิเสธคำสั่งซื้อ'
  },
  policy: {
    details: {
      orderId: 'Order ID',
      orderNo: 'Order Number',
      policyNumber: 'Policy Number',
      copy: 'สำเนา',
      copyToast: 'สำเนา',
      status: 'Status',
      statusMap: {
        1: 'ยังไม่มีผลคุ้มครอง',
        2: 'มีผลคุ้มครอง',
        3: 'หมดอายุ',
        4: 'เวนคืน'
      },
      slipStatusMap: {
        1: 'รอชำระเบี้ย',
        2: 'รอกรมธรรม์',
        3: 'ได้รับการอนุมัติ',
        4: 'ถูกปฏิเสธ'
      },
      paymentStatus: 'Payment Status',
      paymentStatusMap: {
        1: 'ยังไม่ชำระ',
        2: 'ชำระแล้ว',
        3: 'ยกเลิก'
      },
      insuranceCompanyFive: 'Insurance Company Five',
      seeDetails: 'See Details',
      viewDetails: 'Xem chi tiết',
      coveragePeriod: 'Coverage Period',
      orderTime: 'Order Time',
      paymentTime: 'Payment Time',
      benefitDetails: 'Benefit Details',
      claimdetails: 'Claim Details',
      payNow: 'Pay Now'
    },
    benkTransferProof: 'Bank Transfer Proof'
  },
  policyDetails: 'รายละเอียดกรมธรรม์',
  confirmProceed: 'ยืนยันและดำเนินการ',
  queryCompError: 'มีบางอย่างผิดปกติกับราคา โปรดเลือกอีกครั้ง',

  partnershipAgree: 'ข้าพเจ้าขอยืนยันว่าได้อ่านและเข้าใจเนื้อหาของข้อตกลงการเป็นหุ้นส่วนแล้ว จึงตกลงที่จะเข้าร่วมในข้อตกลงดังกล่าวกับ บริษัท เออีซี อินชัวรันซ์ โบรกเกอร์ จำกัด',
  placeholderSearch: 'กรุณาใส่คำค้นหา',

  carInspection: 'รูปถ่ายรถสำหรับการตรวจสอบ',
  uploadVehiclePhotos: 'อัปโหลดรูปภาพยานพาหนะ',
  confirmSubmit: 'คุณแน่ใจหรือไม่ว่าต้องการส่ง',
  confirmDraft: 'Are you sure you want to save as draft?',
  inspectionSubmitTip: 'รูปภาพที่แนบมาไม่สามารถแก้ไขได้เมื่อส่งแล้ว',

  province: 'จังหวัด',
  city: 'เมือง',
  district: 'อำเภอ',
  loading: 'กำลังโหลด...',

  // Coupon相关
  noCoupon: 'ไม่มีคูปองที่สามารถใช้ได้กับรายการนี้',
  changeCoupon: 'เปลี่ยนคูปอง',
  addCoupon: 'เพิ่มคูปอง',
  couponAvailable: 'มีคูปองที่สามารถใช้ได้',
  totalAmount: 'จำนวนเงินทั้งหมด',
  couponsInformation: 'ข้อมูลคูปอง',

  paymentInformation: 'รายละเอียดการชำระเงิน',
  insuredName: 'ชื่อผู้เอาประกันภัย',
  paymentFlow: 'ประเภทการชำระเงิน',
  paymentType: 'วิธีการชำระเงิน',
  paymentScheme: 'รูปแบบการชำระเงิน',
  premiumBenificiary: 'บริษัทประกันภัย',

  billingInformation: 'ข้อมูลการเรียกเก็บเงิน',
  billingNo: 'หมายเลขการเรียกเก็บเงิน',
  billingStatus: 'สถานะการเรียกเก็บเงิน',
  paymentDueAt: 'วันครบกำหนดชำระเงิน',
  billingDetail: 'รายละเอียดการเรียกเก็บเงิน',

  walletStatement: 'รายการการเงิน',

  billlingNo: 'หมายเลขการเรียกเก็บเงิน',
  comissionInformation: 'รายละเอียดค่าตอบแทน',
  partnerCommision: 'ค่าตอบแทนพาร์ทเนอร์',
  partnerCommisionVAT: 'ภาษีมูลค่าเพิ่มของค่าตอบแทนพาร์ทเนอร์',
  partnerCommisionIncomeTax: 'ภาษีเงินได้ของค่าตอบแทนพาร์ทเนอร์',
  partnerSpecialBonus: 'โบนัสพิเศษ',
  partnerSpecialBonusVAT: 'ภาษีมูลค่าเพิ่มของโบนัสพิเศษ',
  partnerSpecialBonusIncomeTax: 'ภาษีเงินได้ของโบนัสพิเศษ',

  walletDeductionInformation: 'ข้อมูลการหักเงิน',
  walletDeductionStatement: 'รายการการหักเงิน',
  bonusDeduction: 'การหักโบนัส',
  pointDeduction: 'การหักคะแนน',
  finalPayableAmount: 'จำนวนเงินที่ต้องชำระทั้งหมด',
  totalPaymentAmount: 'จำนวนเงินที่ต้องชำระทั้งหมด',
  payableAmount: 'จำนวนเงินที่ต้องชำระ',

  billingStatusMap: {
    1: 'กำลังดำเนินการ',
    2: 'รอยืนยัน',
    3: 'ยกเลิก',
    4: 'สำเร็จ'
  },
  paymentFlowMap: {
    '1': 'ชำระทันที',
    '0': 'ชำระภายหลัง'
  },
  paymentSchemaMap: {
    1: 'ช่ำระเงินเต็มจำนวน',
    2: 'ชำระเงินโดยหักโบนัส',
    3: 'ชำระเงินโดยหักโบนัส และคะแนน'
  },
  payerTypeMap: {
    1: 'ลูกค้า',
    2: 'พาร์ทเนอร์'
  },
  draft: 'Draft',
  claim: {
    next: 'Next',
    back: 'Back',
    Car: 'Car',
    Moto: 'Moto',
    Travel: 'Travel',
    Property: 'Property',
    submitClaim: 'Submit Claim',
    fillInBy: 'Fill in by',
    claimSaveDraft: 'Are you sure you want to save a draft?',
    claimSaveSubmit: 'Are you sure you want to submit?',
    claimSaveInspectionDraftTip: 'You can change inspection information after save',
    claimSaveInspectionSubmitTip: 'You can not change inspection information after submission.',
    claimSaveApplicaiontDraftTip: 'You can stil change information after save',
    claimSaveApplicaiontSubmitTip: 'You can not change after submission.',
    ClaimSaveDraftSuccess: 'Save a Draft Successfully',
    ClaimSaveSubmitSuccess: 'Submit Successfully',
    Draft: 'Draft',

    propertyRulesAddress: 'Address is required',
    propertyRulesDetail: 'Address detail is required',
    propertyRulesOccupation: 'Occupation is required',

    travelRulesBirthday: 'Birthday is required',
    travelRulesFirstName: 'Fisrt Name is required',
    travelRulesLastName: 'Last Name is required',
    travelRulesPassportNo: 'Passport Numberis required',

    carRulesPlateCode: 'Plate code is required',
    carRulesPlateNo: 'Plate No. is required',
    carRulesChassisNo: 'Chassis No. is required',
    carRulesEngineNo: 'Engine No. is required',

    motoRulesPlateCode: 'Plate code is required',
    motoRulesPlateNo: 'Plate No. is required',
    motoRulesChassisNo: 'Chassis No. is required',
    motoRulesEngineNo: 'Engine No. is required',

    reporterNameRules: 'Reporter Name is required',
    reporterMobileRules: 'Reporter Mobile is required',
    reporterEmailRules: 'Reporter Email is required',

    policyNoRules: 'Policy No. is required',

    ChooseYourClaimProduct: 'Choose Your Claim Product',
    Reportby: 'Report by',
    InsuredObject: 'Insured Object',
    PolicyNoLabel: 'Policy No',
    PlateCodeLabel: 'Plate Code',
    PlateNumberLabel: 'Plate Number',
    ChassisNumberLabel: 'Chassis Number',
    EngineNumberLabel: 'Engine Number',
    BuildingsAddressLabel: 'Buildings Address',
    RiskLocationDetailAddressLabel: 'Risk Location Detail Address',
    OccupationLabel: 'Occupation',
    BirthdayLabel: 'Birthday',
    FirstNameLabel: 'First Name',
    LastNameLabel: 'Last Name',
    PassportNoLabel: 'Passport No.',
    RulesDateOfLoss: 'Date Of Lossis required',
    DateOfLossLabel: 'Date of loss',

    Period: 'Period',
    InsuredNameLabel: 'Insured Name',
    ReporterNameLabel: 'Reporter Name',
    ReporterMobileLabel: 'Reporter Mobile',
    ReporterEmailLabel: 'Reporter Email',
    ReportSubmitSuccessTitle: 'Well done! Your report has been success.',
    ReportSubmitSuccessMessage: 'Please continue to fill in the claim document.',

    ReportInformationTitle: 'Report Information',
    AddInspectionForm: 'Add Inspection Form',
    ClaimSuccessPageMainTitle: 'You have reported successfully!',
    ClaimSuccessPageSubTitle: 'We will register your claim after review',
    ClaimSuccessPageTrackingClaim: 'Tracking Claim',
    ClaimSuccessPageHomePage: 'Home Page',
    ReportIDLabel: 'Report ID',
    ReportStatusLabel: 'Report Status',
    SlipNoLabel: 'Slip No',
    ProductNameLabel: 'Product Name',
    EffectivePeriodLabel: 'Effective Period',
    ReporterInformationTitle: 'Reporter Information',
    BasicInformationTitle: 'Basic Information',
    InsuranceInformationTitle: 'Insurance Information',
    ClaimNo: 'Claim No',
    ClaimSubmissionStatusLabel: 'Claim Submission Status',
    Thankyou: 'Thank you',
    InspectionsAreSavedText: 'Inspections are saved',
    ContinueToFinishText: 'Continue to finish',
    shareMainTitle: 'Share Link for Customer Questionnaire',
    shareSubTitle: 'Please send this link to customer for easy fill up questioner',
    sendTxt: 'or you can send this link with :',
    View: 'ดู'
  },
  livephoto: {
    text1: 'Please adjust the vehicle position',
    text2: 'In the middle of the screen',
    turnOffrotation: 'Please turn off the automatic rotation function on your phone',
    Continue: 'Continue',
    Retake: 'Retake',
    Loading: 'Loading...',
    Back: 'Back',
    invalidTips: 'Invalid photo requires manual review which takes you a longer time'
  }
}

export default th
